<template>
	<div class="ion-padding">
		<!-- Errors -->
		<div v-if="isError === true">
			<div v-for="error in errors" :key="error">
				<div v-for="el in error" :key="el">
					<ion-item>
						<div tabindex="0"></div><!-- bug fix for safari 14 -->
						<ion-text color="danger">{{el}}</ion-text>
					</ion-item>
				</div>
			</div>
		</div>
		<!-- Email -->
		<ion-item>
			<div tabindex="0"></div><!-- bug fix for safari 14 -->
			<ion-input v-model="email" type="email" placeholder="Email Address"></ion-input>
		</ion-item>
		<!-- Password -->
		<ion-item>
			<div tabindex="0"></div><!-- bug fix for safari 14 -->
			<ion-input v-model="password" type="password" placeholder="Password"></ion-input>
		</ion-item>
		<!-- Submit -->
		<div v-if="email != null && email != '' && password != null && password != ''">
			<ion-item>
				<div tabindex="0"></div><!-- bug fix for safari 14 -->
				<ion-button expand="full" color="primary" @click.prevent="handleLogin()">Sign In</ion-button>
			</ion-item>
		</div>
	</div>
</template>

<script>
import { IonItem, IonButton, IonText, loadingController, IonInput } from '@ionic/vue';
import apiClient from '../services/api';
import { defineComponent, ref } from 'vue';
import { useStore } from "vuex";
import { useRouter } from 'vue-router';

export default defineComponent( {
	components: { IonItem, IonButton, IonText, IonInput },
	name: 'SignIn',
	setup() {
		const email = ref(null);
		const password = ref(null);
		const isError = ref(false);
		const errors = ref(null);
		const router = useRouter();
		const store = useStore();
		const timeout = { default: 6000 }

		function handleLogin() {
			//console.log('test safari')
			presentLoading()
			// eslint-disable-next-line no-unused-vars
			apiClient.get('/sanctum/csrf-cookie').then(response => {
				// eslint-disable-next-line no-unused-vars
				apiClient.post('/api/login', {
					email: email.value,
					password: password.value
				}).then(response => {
					console.log(response)	
					if(response.status === 201) {
						store.commit('setIsAuth', true);
						store.commit('setAuthToken', response.data.token);
						store.commit('setAuthUser', response.data.user);
						if(response.data.user.status == 1) {
							store.commit('setActiveUser', true);
							localStorage.setItem('activeUser', true)
							router.push('home')
						} else {
							store.commit('setActiveUser', false);
							localStorage.setItem('activeUser', false)
							router.push('on-boarding')							
						}
						localStorage.setItem('authUser', JSON.stringify(response.data.user));
						localStorage.setItem('authToken', response.data.token)
						localStorage.setItem('isAuth', true)
						console.log(response)						
					} else {
						isError.value = true
					}
				}).catch(error => {
					console.log(error)	
					// credentials didn't match
					if(error.response.status === 422) {
						isError.value = true
						errors.value = error.response.data.errors
					}
					if(error.response.status === 404) {
						isError.value = true
						errors.value = error.response.data.errors
					}
				}); 
			});
		}

		async function presentLoading() {
			const loading = await loadingController
			.create({
				cssClass: 'my-custom-class',
				message: 'Please wait...',
				duration: timeout,
			});

			await loading.present();

			setTimeout(function() { loading.dismiss() }, timeout);
		}

		function goToSignUp() {
			store.commit('setDisplayForm', 'signup');
		}

		return { handleLogin, goToSignUp, presentLoading, router, email, password, isError, errors }
	}
});
</script>
<style scoped>
#container {
  text-align: center;
  
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
</style>