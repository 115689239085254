<template>
  <ion-page>
    <ion-content :fullscreen="true">
      <div id="container">
        <div class="app-max-width">
          <img src="https://s3-us-west-1.amazonaws.com/artistwavesoftware/posts/5ec9ccd8554f8.png" style="width:100%;max-width:240px;" />
          <SignIn />
          <ion-button color="dark" @click.prevent="updateRoute('sign-up')">Sign Up</ion-button>
        </div>
      </div>
    </ion-content>
  </ion-page>

</template>

<script>
import { IonContent, IonPage, IonButton } from '@ionic/vue';
import { defineComponent } from 'vue';
import SignIn from '../components/SignIn.vue'
import { useRouter } from 'vue-router';

export default defineComponent({
  name: 'SignUpPage',
  components: {
    IonContent,
    IonPage,
    SignIn, IonButton
  },
  setup() {
    const router = useRouter();
    
    function updateRoute(r) {
      router.push(r) 
    }

    return {
      router, updateRoute
    }
  }
});
</script>

<style scoped>
ion-content{
    --background: url(https://artistwavesoftware.s3-us-west-1.amazonaws.com/posts/600b62882c6b5.jpeg) no-repeat center center / cover;
}
#container {
  text-align: center;
  
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

#container strong {
  font-size: 20px;
  line-height: 26px;
}

#container p {
  font-size: 16px;
  line-height: 22px;
  
  color: #8c8c8c;
  
  margin: 0;
}

#container a {
  text-decoration: none;
}
</style>